import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import CalendarIcon from "../common/calendarIcon"
import styles from "./modalOnlineWorkshop.module.css"

export default function ModalOnlineWorkShop() {
  const data = useStaticQuery(graphql`
    query {
      modalText: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(home/modal_onlineWorkshop.md)/" } }
      ) {
        edges {
          node {
            frontmatter {
              date
              day
              month
            }
            html
          }
        }
      }
    }
  `)

  return (
    <div className={styles.wrapper}>
      <div className={styles.calendarBar}>
        <div>
          <CalendarIcon 
            month={data.modalText.edges[0].node.frontmatter.month}
            day={data.modalText.edges[0].node.frontmatter.day}
          />
        </div>
        <div>
          <p>
            <strong>
              Online Workshop
            </strong>
          </p>
        </div>       
      </div>
      <div className={styles.dateInfo}>{data.modalText.edges[0].node.frontmatter.date}</div>
      <div
        dangerouslySetInnerHTML={{
          __html: data.modalText.edges[0].node.html,
        }}
      />
    </div>
  )
}