import React, { useEffect, useState } from "react"
import styles from "./adModal.module.css"
import Cookies from "universal-cookie"

export default function AdModal(props) {

  const [isOpen, setIsOpen] = useState(false);
  const cookies = new Cookies();
  const cookieName = `babschlafexpertin-modal-${props.uid}`;
  const cookieContent = cookies.get(cookieName);

  useEffect(
    () => {
      if (!cookieContent || cookieContent < props.N) {
        setIsOpen(true);
      }
    }, []
  );

  const setCookie = () => {
    if (cookieContent === undefined) {
      cookies.set(cookieName, 1);
    } else {
      if (cookieContent < props.N) {
        cookies.set(cookieName, parseInt(cookieContent)+1);
      }
    }
  }

  const closeModal = () => {
    setCookie();
    setIsOpen(false);
  }

  const actionModal = () => {
    setCookie();
    props.onAction();
  }

  return (
    <>
    {
      isOpen && (
        <div className={styles.wrapper}>
          <div className={`${styles.modal}`}>
            <a href="#" class={styles.close} onClick={closeModal} />
            <div className={styles.modalContent}>
              {props.children}
            </div>
            <div className={styles.modalFooter}>
              <button
                className={`action`}
                onClick={actionModal}
              >
                {props.actionText}
              </button>
            </div>
          </div>
        </div>
      )
    }



    </>
  )
}