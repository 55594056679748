import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Signature from "../images/signature"
import styles from "./contactUs.module.css"

export default function ContactUs(props) {
  const data = useStaticQuery(graphql`
    query {
      contentContactUs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(common/contactUs.md)/" } }
      ) {
        edges {
          node {
            frontmatter {
              actionButton
            }
            html
          }
        }
      }
    }
  `)

  return (
    <div className={styles.wrapper}>
      <div
        dangerouslySetInnerHTML={{
          __html: data.contentContactUs.edges[0].node.html,
        }}
      />
      <div className={styles.buttonCenter}>
        <Signature />
      </div>
    </div>
  )
}
