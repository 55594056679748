import React from "react"
import config from "../../data/SiteConfig"
import AdModal from "../components/common/adModal"
import ContactUs from "../components/common/contactUs"
import GoToFAQ from "../components/common/goToFAQ"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Intro from "../components/home/intro"
import ModalOnlineWorkShop from "../components/home/modalOnlineWorkshop"
import googleAnalyticsTrackingService from "../services/googleAnalyticsTrackingService"

const title = `babyschlafexpertin`

const IndexPage = () => (
  <Layout title={title} useWelcomeHeader={true}>
    <SEO title={title} pathname="/">
      <script type={`application/ld+json`}>{`
        {
          "@context": "https://schema.org/",
          "@type": "Service",
          "name": "${config.organization} ${config.author}",
          "alternateName": "${config.organization}",
          "audience": {
            "@type": "Audience",
            "audienceType": "parents"
          },
          "category": "Schlafcoaching",
          "provider": {
            "@type": "Person",
            "givenName": "${config.authorFirstName}",
            "additionalName": "${config.authorAltName}",
            "familyName": "${config.authorLastName}",
            "affiliation": {
              "@type": "Organization",
              "name": "${config.organization} ${config.author}"
            },
            "email": "${config.email}"
          },
          "serviceType": "ParentalSupport",
          "description": "${config.description}"
        }
      `}</script>
    </SEO>
    <Intro />
    <GoToFAQ />
    <ContactUs />
    {
      config.showAdModal && (
        <AdModal 
          actionText={`Sei dabei`}
          onAction={
            () => {
              googleAnalyticsTrackingService.bookOnlineWorkshop();
              window.location.href=config.adModalUrl
            }
          }
          uid={config.adModalCookieUid}
          N={2}
        >
          <ModalOnlineWorkShop />
        </AdModal>
      )
    }
  </Layout>
)

export default IndexPage
