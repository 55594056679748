import React from "react"
import styles from "./calendarIcon.module.css"

export default function CalendarIcon(props) {
  return (
    <>
      <div className={styles.date}>
        <span className={styles.binds}></span>
        <span className={styles.month}>{props.month}</span>
        <h1 className={styles.day}>{props.day}</h1>
      </div>
    </>
  )
}