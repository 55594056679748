import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ImgWithText from "../common/imgWithText"
import ResponsiveImage from "../common/responsiveImage"
import ResponsiveImageBadge from "../common/responsiveImageBadge"
import ResponsiveImageText from "../common/responsiveImageText"
import BabyFist from "../images/babyFist"
import BabyTongue from "../images/babyTongue"
import styles from "./intro.module.css"
import config from "../../../data/SiteConfig"
import BabyMumSquare from "../images/babyMumSquare"

export default function Intro() {
  const data = useStaticQuery(graphql`
    query {
      contentIntro: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(home/1_intro.md)/" } }
      ) {
        edges {
          node {
            frontmatter {
              actionButton
            }
            html
          }
        }
      }
      contentItIsWhatItIs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(home/2_itIsWhatItIs.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
      contentFistText: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(home/3_fistText.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
      contentWhatYouMakeFromIt: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(home/4_whatYouMakeFromIt.md)/" }
        }
      ) {
        edges {
          node {
            html
          }
        }
      }
      contentWeCanHelp: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(home/5_weCanHelp.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
      contentBabyText: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(home/6_babyText.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  return (
    <div id={`start`} className={styles.content}>
      <ResponsiveImageText
        image={
          <ResponsiveImage
            portrait={<div />}
            landscape={<BabyMumSquare />}
            breakpoint={config.layoutBreakpointDesktop}
          />
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data.contentIntro.edges[0].node.html,
          }}
        />
      </ResponsiveImageText>
      <div
        dangerouslySetInnerHTML={{
          __html: data.contentItIsWhatItIs.edges[0].node.html,
        }}
      />
      <div className={styles.imageWrapper}>
        <ResponsiveImageBadge>
          <ImgWithText
            content={data.contentFistText.edges[0].node.html}
            contentClass={styles.fistContent}
          >
            <BabyFist />
          </ImgWithText>
        </ResponsiveImageBadge>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: data.contentWhatYouMakeFromIt.edges[0].node.html,
        }}
      />
      <div className={styles.weCanHelp}>
        <div
          dangerouslySetInnerHTML={{
            __html: data.contentWeCanHelp.edges[0].node.html,
          }}
        />
      </div>
      <div className={styles.imageWrapper}>
        <ResponsiveImageBadge>
          <ImgWithText
            content={data.contentBabyText.edges[0].node.html}
            contentClass={styles.tongueContent}
          >
            <BabyTongue />
          </ImgWithText>
        </ResponsiveImageBadge>
      </div>
    </div>
  )
}
