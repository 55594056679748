import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styles from "./goToFAQ.module.css"
import InfoIcon from "../../images/info.svg"


export default function GoToFAQ(props) {
  const data = useStaticQuery(graphql`
    query {
      contentContactUs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(common/goToFAQ.md)/" } }
      ) {
        edges {
          node {
            frontmatter {
              actionButton
            }
            html
          }
        }
      }
    }
  `)

  return (
    <div className={styles.wrapper}>
      <InfoIcon className={styles.icon} />
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{
          __html: data.contentContactUs.edges[0].node.html,
        }}
      />
    </div>
  )
}